import toast from "react-hot-toast";

function transformEmptyStringsToNull(data: any): any {
  if (typeof data === "object" && data !== null) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] === "") {
          data[key] = null;
        } else if (typeof data[key] === "object") {
          data[key] = transformEmptyStringsToNull(data[key]);
        }
      }
    }
  }
  return data;
}

export async function makeAPIRequest(
  path: string,
  method: string,
  data: any = null,
  onError?: (error: any) => void
): Promise<any> {
  data = transformEmptyStringsToNull(data);

  const fetchRequest: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : null,
    credentials: "include",
  };

  if (path.startsWith("/")) {
    path = path.slice(1);
  }

  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/${path}`,
      fetchRequest
    );
    if (!res.ok) {
      if (onError) {
        return onError(res);
      }
      if (res.status === 404 && method === "GET") {
        return [];
      }
      if (res.status === 401) {
        window.location.href = `/login?redirect=${encodeURIComponent(
          window.location.pathname
        )}`;
      }
      const jsonResponse = await res.json();
      throw new Error(`An error has occurred.\n\n${jsonResponse?.message}`);
    }
    if (
      res.headers.get("content-type") &&
      res.headers.get("content-type") === "application/json; charset=utf-8"
    ) {
      const jsonResponse = await res.json();
      return jsonResponse;
    }
  } catch (error: any) {
    if (onError) {
      return onError(error);
    }
    let errorMessage = "An error has occurred";
    if (error?.message !== "Failed to fetch") {
      errorMessage = error.message;
    }
    toast.error(errorMessage);
    throw error;
  }
}
