"use client";
import { Fragment, useEffect, useState, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { classNames } from "../utils/tailwindHelper";
import { makeAPIRequest } from "../api/apiWrapper";
import { UserContext } from "../utils/user";

interface Notification {
  id: number;
  message: string;
  link: string;
}

const NotificationMenu: React.FC = () => {
  const [activeNotifications, setActiveNotifications] = useState<
    Notification[]
  >([]);
  const user = useContext(UserContext).user;
  const companyId = user?.company?.id;

  const fetchNotifications = async () => {
    if (companyId) {
      const data = await makeAPIRequest(
        `/company/${companyId}/notification`,
        "GET",
        undefined,
        () => {
          // do nothing with a notification fetch error...
        }
      );
      setActiveNotifications(data);
    }
  };

  const dismissNotification = async (id: number) => {
    await makeAPIRequest(`/company/${companyId}/notification/${id}`, "DELETE");
    setActiveNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  useEffect(() => {
    fetchNotifications();
  }, [companyId]);

  return (
    <Menu as="div" className="relative h-6">
      <Menu.Button
        className="relative text-white"
        disabled={!activeNotifications?.length}
      >
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
        {activeNotifications?.length > 0 && (
          <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            {activeNotifications.length}
          </span>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-80 origin-top-right bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-200">
          {activeNotifications?.map((notification) => (
            <Menu.Item key={notification.id}>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "px-4 py-2 text-sm text-gray-900 flex justify-between items-center"
                  )}
                >
                  <a
                    href={notification.link}
                    className="flex-1"
                    // onClick={() => dismissNotification(notification.id)}
                  >
                    {notification.message}
                  </a>
                  <button
                    onClick={() => dismissNotification(notification.id)}
                    className="text-blue-600 hover:text-blue-800 text-xs ml-2 underline"
                  >
                    Dismiss
                  </button>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationMenu;
