"use client";

import { usePathname } from "next/navigation";
import Link from "next/link";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";

const getBreadCrumbs = (pathname: string) => {
  const regex = new RegExp("/open-rounds/filters/\\d+/company/\\d+");
  if (regex.test(pathname)) {
    const ids = pathname.match(/\d+/g) as string[];
    return [
      {
        name: "Open Rounds",
        href: "/open-rounds",
      },
      {
        name: "Filter",
        href: `/open-rounds/filters/${ids[0]}/`,
      },
      {
        name: "Deal",
        href: `/open-rounds/filters/${ids[0]}/company/${ids[1]}`,
      },
    ];
  }

  const regex2 = new RegExp("/open-rounds/filters/\\d+");
  if (regex2.test(pathname)) {
    const ids = pathname.match(/\d+/g) as string[];
    return [
      {
        name: "Open Rounds",
        href: "/open-rounds",
      },
      {
        name: "Filter",
        href: `/open-rounds/filters/${ids[0]}/`,
      },
    ];
  }

  const regex3 = new RegExp("/open-rounds/company/\\d+");
  if (regex3.test(pathname)) {
    const ids = pathname.match(/\d+/g) as string[];
    return [
      {
        name: "Open Rounds",
        href: "/open-rounds",
      },
      {
        name: "Deal",
        href: `/open-rounds/company/${ids[0]}/`,
      },
    ];
  }

  return [
    {
      name: "Open Rounds",
      href: "/open-rounds",
    },
  ];
};

export default function BreadCrumb() {
  const pathname: string = usePathname() as string;

  const pathSegments = pathname.split("/").filter((segment) => segment);
  if (pathSegments[0] !== "open-rounds") {
    return <></>;
  }

  const pages: any[] = getBreadCrumbs(pathname);

  // const pages = pathSegments.map((segment, index) => {
  //   let href = '/' + pathSegments.slice(0, index + 1).join('/');

  //   if (segment === 'company') {
  //     href = '/' + pathSegments.slice(0, index).join('/');
  //   }

  //   const name = decodeURIComponent(segment)
  //     .replace(/-/g, ' ')
  //     .replace(/\b\w/g, (char) => char.toUpperCase());

  //   return {
  //     name,
  //     href,
  //     current: index === pathSegments.length - 1,
  //   };
  // });

  return (
    <nav aria-label="Breadcrumb" className="flex px-4 sm:px-6 py-2 bg-gray-100">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link href="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.href}>
            <div className="flex items-center">
              <ChevronRightIcon
                aria-hidden="true"
                className="h-5 w-5 flex-shrink-0 text-gray-400"
              />
              <Link
                href={page.href}
                aria-current={page.current ? "page" : undefined}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
